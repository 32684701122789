import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, message, Spin, Pagination } from "antd";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// Define the columns for the Antd Table
const columns = [
  {
    title: "Group",
    dataIndex: "groupName",
    key: "groupName",
  },
  {
    title: "From",
    dataIndex: "notifyName",
    key: "notifyName",
  },
  {
    title: "Message Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Message",
    dataIndex: "body",
    key: "body",
  },
  {
    title: "Caption",
    dataIndex: "caption",
    key: "caption",
  },
  {
    title: "Date",
    dataIndex: "createdOn",
    render: (text) =>
      new Date(text).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }),
  },
];
const Details = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Media query hook to detect mobile view
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    fetchDetails(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  const fetchDetails = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://thinkzone.info/getAllDetails?page=${page}&limit=${pageSize}`
      );
      if (response.status === 200) {
        setData(response.data.data);
        setPagination({
          ...pagination,
          total: response.data.total,
          current: page,
        });
      } else {
        message.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  return (
    <Container>
      <Header>
        <h2>Total record(s): {pagination.total}</h2>
      </Header>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          {!isMobile ? (
            <Table
              columns={columns}
              dataSource={data}
              loading={loading}
              rowKey="_id"
              pagination={{
                ...pagination,
                showSizeChanger: false,
                total: pagination.total,
                onChange: handlePaginationChange,
              }}
              onChange={handleTableChange}
              scroll={{ x: 800, y: 400 }} // Adjust scroll values as needed
            />
          ) : (
            <>
              <CardContainer>
                {data.map((item) => (
                  <Card key={item._id}>
                    <div className="card-header">Group: {item.groupName}</div>
                    <div className="card-body">From: {item.notifyName}</div>
                    <div className="card-body">Message Type: {item.type}</div>
                    <div className="card-body">Message: {item.body}</div>
                    <div className="card-body">Caption: {item.caption}</div>
                    <div className="card-body">
                      Date:{" "}
                      {new Date(item.createdOn).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </Card>
                ))}
              </CardContainer>

              <PaginationContainer>
                <Pagination
                  current={pagination.current}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  onChange={handlePaginationChange}
                  showSizeChanger={false} // Disable page size change for simplicity
                />
              </PaginationContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Details;

const Container = styled.div`
  padding: 16px;
  max-width: 100%;
  box-sizing: border-box;
  margin-left: 300px;
  margin-left: 235px;

  @media (max-width: 1200px) {
    margin-left: 0px; // Adjust margin for smaller screens
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Card = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 18px black;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 16px 0;
  }

  .card-header {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .card-body {
    margin-bottom: 8px;
  }
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const PaginationContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;
