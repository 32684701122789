import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentIcon from "@mui/icons-material/Payment";
import SmsIcon from "@mui/icons-material/Sms";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import ParentGuardianIcon from "@mui/icons-material/EmojiPeople";
import Feed from "@mui/icons-material/Feed";
import ReportIcon from "@mui/icons-material/Report";
import Mail from "@mui/icons-material/Mail";

export const side_bar_menu = [
  { text: "Dashboard", path: "/", icon: <HomeIcon /> },
  { text: "Group", path: "/groups", icon: <GroupIcon /> },
  { text: "Nudge Message", path: "/nudgemessage", icon: <Mail /> },
  { text: "Report", path: "/details", icon: <Feed /> },
];
