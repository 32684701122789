import connection from "../axios/connection";

export const getAllGroups = async () => {
  try {
    const response = await connection.get("getAllGroups");
    if (response.data.status === 200) {
      return response.data;
    } else {
      console.error("Failed to fetch groups: " , response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching groups:", error);
    return null;
  }
};
