import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { side_bar_menu } from "../menu/menus";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import logo from "../assets/logo.png";
const drawerWidth = 240;

export default function ClippedDrawer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSubMenus, setOpenSubMenus] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleSubMenuToggle = (text) => {
    setOpenSubMenus((prevOpen) => ({
      ...prevOpen,
      [text]: !prevOpen[text],
    }));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = () => {
  //   // Implement logout functionality here
  //   handleMenuClose();
  //   // Example: navigate('/login');
  // };

  const handleProfile = () => {
    // Implement profile navigation here
    handleMenuClose();
    // Example: navigate('/profile');
  };
  const handleLogout = () => {
    handleMenuClose();
    if (isMobile) {
      setMobileOpen(false); 
    }
    navigate("/logout"); 
  };
  const drawer = (
    <div>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {side_bar_menu.map((menu, index) => (
            <React.Fragment key={menu.text}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    menu.subMenu
                      ? handleSubMenuToggle(menu.text)
                      : handleNavigation(menu.path)
                  }
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgb(93 139 182 / 55%)",
                      boxShadow: "0 4px 8px rgb(93 139 182 / 55%)",
                    },
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  }}
                >
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.text} />
                  {menu.subMenu ? (
                    openSubMenus[menu.text] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </ListItem>
              {menu.subMenu && (
                <Collapse
                  in={openSubMenus[menu.text]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {menu.subMenu.map((subMenu) => (
                      <ListItem
                        key={subMenu.text}
                        disablePadding
                        sx={{ pl: 4 }}
                      >
                        <ListItemButton
                          onClick={() => handleNavigation(subMenu.path)}
                        >
                          <ListItemIcon>{subMenu.icon}</ListItemIcon>
                          <ListItemText primary={subMenu.text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 2 }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: isMobile ? 30 : 50,
                height: isMobile ? 30 : 50,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px black",
                borderRadius: "100%",
                transition: "transform 0.3s ease",
                transform: "perspective(1000px) rotateX(10deg) rotateY(10deg)",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform =
                  "perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1.05)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform =
                  "perspective(1000px) rotateX(10deg) rotateY(10deg)";
              }}
            />

            <Typography
              variant={isMobile ? "h6" : "h5"}
              noWrap
              component="div"
              sx={{ ml: 1 }}
            >
              ThinkZone WA Group Automation
            </Typography>
          </Box>
          <IconButton onClick={handleMenuClick} color="primary">
            <Avatar
              alt="Dhaneswar"
              src="/static/images/avatar/5.jpg"
              color="primary"
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => navigate("/profile")}>
              <ListItemIcon>
                <AccountCircle fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
  <ListItemIcon>
    <LogoutIcon fontSize="small" />
  </ListItemIcon>
  Logout
</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* Mobile Drawer */}
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </SwipeableDrawer>

        {/* Permanent Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* Your main content goes here */}
      </Box>
    </Box>
  );
}
