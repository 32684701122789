import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const NudgeMessages = () => {
  const [message, setMessage] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const maxChars = 700;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSend = () => {
    if (charCount > maxChars) {
      setErrorMessage(`Message exceeds the ${maxChars}-character limit.`);
      alert(`Message exceeds the ${maxChars}-character limit.`);
      return;
    }
    console.log("Message sent:", message);
    // Clear the text area after sending the message
    setMessage("");
    setCharCount(0);
    setErrorMessage("");
  };

  const handleChange = (e) => {
    const text = e.target.value;
    setMessage(text);
    setCharCount(text.length);

    // Reset error message if characters are within the limit
    if (text.length <= maxChars) {
      setErrorMessage("");
    }
  };

  return (
    <Container>
      <Header>
        <h2 style={{ textAlign: "center", fontWeight: "bolder" }}>
          NudgeMessages
        </h2>
      </Header>
      <MessageBox>
        <TextArea
          value={message}
          onChange={handleChange}
          placeholder="Type your message here..."
          hasError={charCount > maxChars}
        />
        <CharCount>
          {charCount} / {maxChars}
        </CharCount>
        {errorMessage && <Error>{errorMessage}</Error>}
        <SendButton onClick={handleSend} disabled={charCount > maxChars}>
          SUBMIT
        </SendButton>
      </MessageBox>
    </Container>
  );
};

export default NudgeMessages;

const Container = styled.div`
  padding: 16px;
  max-width: 100%;
  box-sizing: border-box;
  margin-left: 235px;

  @media (max-width: 1200px) {
    margin-left: 0px; // Adjust margin for smaller screens
  }
`;

const Header = styled.div`
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const TextArea = styled.textarea`
  width: 100%;
  max-width: 600px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ hasError }) => (hasError ? "red" : "#ccc")};
  background-color: ${({ hasError }) => (hasError ? "#ffe6e6" : "white")};
  box-sizing: border-box;
  resize: none;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto; 

  @media (max-width: 768px) {
    max-width: 100%;
    min-height: 80px;
  }
`;

const CharCount = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  margin-right: "20%";
`;

const Error = styled.p`
  color: red;
  margin-top: 8px;
`;

const SendButton = styled.button`
  margin-top: 12px;
  padding: 10px 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 10%;
  &:hover {
    background-color: #40a9ff;
  }

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile devices
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
