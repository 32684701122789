import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = () => {
    if (isLoggingOut) return; // Prevent multiple clicks
    setIsLoggingOut(true); // Set to true on the first click

    // Execute logout logic
    onLogout();
    localStorage.removeItem("isLogedIn");

    // Navigate immediately without further delay
    navigate("/", { replace: true });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <LogoutContainer>
      <LogoutBox>
        <h1>Logout</h1>
        <LogoutMessage>Are you sure you want to log out?</LogoutMessage>
        <ButtonContainer>
          <LogoutButtonLogout onClick={handleLogout} disabled={isLoggingOut}>
            Log Out
          </LogoutButtonLogout>
          <LogoutButtonCancel onClick={handleCancel}>Cancel</LogoutButtonCancel>
        </ButtonContainer>
      </LogoutBox>
    </LogoutContainer>
  );
};

export default Logout;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const LogoutBox = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
`;

const LogoutMessage = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const LogoutButton = styled.button`
  flex: 1;
  padding: 12px 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    filter: brightness(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const LogoutButtonLogout = styled(LogoutButton)`
  background-color: #ea0606;
  color: white;
`;

const LogoutButtonCancel = styled(LogoutButton)`
  background-color: #636099;
  color: white;
`;
