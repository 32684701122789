import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
} from "recharts";

const Dashboard = () => {
  const navigate = useNavigate();

  // Dummy data for charts
  const data = [
    { name: "Jan", messages: 400, members: 2400 },
    { name: "Feb", messages: 300, members: 2210 },
    { name: "Mar", messages: 200, members: 2290 },
    { name: "Apr", messages: 278, members: 2000 },
    { name: "May", messages: 189, members: 2181 },
    { name: "Jun", messages: 239, members: 2500 },
    { name: "Jul", messages: 349, members: 2100 },
  ];

  return (
    <DashboardContainer>
      <Header>WhatsApp Group Automation ERP</Header>
      {/* <Breadcrumb>Home / Dashboard</Breadcrumb> */}

      <GridContainer>
        <GridItem>
          <GridItemContent>
            <Icon>📊</Icon>
            <Text>Group Analytics</Text>
          </GridItemContent>
          <Badge>New</Badge>
        </GridItem>

        <GridItem>
          <GridItemContent>
            <Icon>💬</Icon>
            <Text>Messages</Text>
          </GridItemContent>
          <Badge>10</Badge>
        </GridItem>

        <GridItem>
          <GridItemContent>
            <Icon>👥</Icon>
            <Text>Members</Text>
          </GridItemContent>
        </GridItem>

        <GridItem>
          <GridItemContent>
            <Icon>⚙️</Icon>
            <Text>Settings</Text>
          </GridItemContent>
        </GridItem>
      </GridContainer>

      <Charts>
        {/* Bar Chart for Messages */}
        <Chart>
          <ChartHeader>Monthly Messages</ChartHeader>
          <ChartBody>
            <BarChart width={300} height={200} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="messages" fill="#007bff" />
            </BarChart>
          </ChartBody>
          <ChartFooter>Updated daily</ChartFooter>
        </Chart>

        {/* Line Chart for Group Growth */}
        <Chart>
          <ChartHeader>Group Growth</ChartHeader>
          <ChartBody>
            <LineChart width={300} height={200} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="members" stroke="#82ca9d" />
            </LineChart>
          </ChartBody>
          <ChartFooter>Updated weekly</ChartFooter>
        </Chart>
      </Charts>

      <Footer>
        <Button onClick={() => navigate("/settings")}>Settings</Button>
        <Button onClick={() => navigate("/members")}>Manage Members</Button>
      </Footer>
    </DashboardContainer>
  );
};

// Styling remains the same
const DashboardContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-left: 235px;
  background-image: url("/path-to-background-image.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 10px;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Breadcrumb = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  color: #888;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GridItem = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const GridItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.span`
  font-size: 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 3px 7px;
  }
`;

const Charts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Chart = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
`;

const ChartHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`;

const ChartBody = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartFooter = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Button = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 3px 7px;
    font-size: 12px;
  }
`;

export default Dashboard;
