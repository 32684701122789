import { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  CssBaseline,
  IconButton,
  InputAdornment,
  CircularProgress,
  Backdrop,
  Alert,
  AppBar,
  Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import bgpic from "../assets/login.png";
import styled from "styled-components";
import Popup from "../components/Popup";

const theme = createTheme({
  palette: {
    primary: { main: "#5e60ce" },
    secondary: { main: "#f72585" },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h4: { fontWeight: 700, fontSize: "2rem", color: "#333" },
    body1: { fontWeight: 300, color: "#555" },
  },
});

const LoginPage = ({ role, onLogin }) => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const staticEmail = "admin@tz.in";
  const staticPassword = "123";

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email?.value;
    const password = event.target.password?.value;

    setEmailError(false);
    setPasswordError(false);
    setMessage("");
    setShowAlert(false);

    if (!email || !password) {
      if (!email) setEmailError(true);
      if (!password) setPasswordError(true);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      setMessage("Invalid email format");
      setShowAlert(true);
      return;
    }

    if (email === staticEmail && password === staticPassword) {
      setLoader(true);
      setTimeout(() => {
        onLogin();
        localStorage.setItem("isLogedIn", "true");
        navigate("/"); // Navigate to the Dashboard
      }, 1000);
    } else {
      setMessage("Invalid Email or Password");
      alert("Invalid Email or Password");
      // setShowPopup(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Header Section */}
      <AppBar position="sticky" sx={{ backgroundColor: "#5e60ce" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
            ThinkZone WA Group Automation
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container component="main" sx={{ height: "calc(100vh - 64px)" }}>
        {/* Left Section with Background */}
        <Grid
          item
          xs={false}
          md={7}
          sx={{
            backgroundImage: `url(${bgpic})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
            position: "relative",
            backgroundSize: "690px",
          }}
        />
        
        {/* Right Section with Form */}
        <Grid
          item
          xs={12}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
          }}
        >
          <Box
            sx={{
              p: 5,
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              borderRadius: "16px",
              boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              {role} Login
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Log in with your email and password
            </Typography>
            {showAlert && (
              <Alert severity="error" onClose={() => setShowAlert(false)}>
                {message}
              </Alert>
            )}
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <StyledTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError}
                helperText={emailError && "Valid email is required"}
              />
              <StyledTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={toggle ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                error={passwordError}
                helperText={passwordError && "Password is required"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setToggle(!toggle)}>
                        {toggle ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </StyledButton>
            </Box>
          </Box>
        </Grid>

        {/* Popup and Backdrop */}
        <Popup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          title="Error"
          message={message}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </ThemeProvider>
  );
};

// Styled Components
const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #5e60ce;
    }
    &.Mui-focused fieldset {
      border-color: #5e60ce;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #f72585, #7209b7);
  color: #fff;
  &:hover {
    background: linear-gradient(45deg, #b5179e, #560bad);
  }
`;

export default LoginPage;
