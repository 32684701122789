import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SimpleDrawer from "./components/DrawerMenus";
import AppRoute from "./AppRoute";
import LoginPage from "./pages/LoginPage";
import Logout from "./pages/Logout";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };
  return (
    <Router>
      {localStorage.getItem("isLogedIn") === "true" ? (
        <>
          <SimpleDrawer />
          <Routes>
            <Route path="/*" element={<AppRoute />} />
            <Route
              path="/logout"
              element={<Logout onLogout={handleLogout} />}
            />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
