import React from "react";
import styled from "styled-components";
import user from "../assets/user.png";

const Profile = () => {
  return (
    <ProfileContainer>
      <ProfileCard>
        <ProfileImage src={user} alt="Profile" />
        <ProfileName>Dhaneswar</ProfileName>
        <ProfileInfo>
          <strong>Email:</strong> dhaneswarsetha.123@gmail.com
        </ProfileInfo>
      </ProfileCard>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileCard = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const ProfileName = styled.h2`
  margin: 20px 0;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
`;

const ProfileInfo = styled.p`
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
`;

export default Profile;
